import React, {useRef, useEffect, useState} from 'react';
import * as QRCode from 'easyqrcodejs';
import mmLogoSmall from '../../assets/mm-logo.png';
import mmLogoLarge from '../../assets/mm-logo--large.svg';
import './QrCode.scss';

function QrCode() {
  let qrCode = useRef(null);
  const [input, setInput] = useState('');
  const [field, setField] = useState(false);
  // const [logout, setLogout] = useState('logGedIn')
  const date = new Date().getFullYear();

  useEffect(() => {
    console.log('QR Code generator created by Karl Weber');
    console.log('www.karlweber.nl');

    if (!input > 0) {
      setField(false);
    } else {
      setField(true);
    }
  },[input]);

  let options = {
    text: input,
    width: 512,
    height: 512,
    logo: mmLogoSmall,
    correctLevel: QRCode.CorrectLevel.H,
    onRenderingEnd: () => { setField(true); },
    drawer: 'canvas',
    logoWidth: 162,
    logoHeight: 162,
    logoBackgroundColor: '#fff',
    logoBackgroundTransparent: false,
  }

  const qrFunction = () => {
    let qrcode = new QRCode(qrCode)
    qrcode.clear();
    new QRCode(qrCode, options);
  }

  const createQR = e => {
    e.preventDefault();
    qrFunction();
    if (field === true) {
      setField(false);
    }
  }

  const downloadQR = () => {
    if (field === true) {
      const canvas = qrCode.childNodes;
      const src = canvas[0].toDataURL();
      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+'-'+today.getHours()+today.getMinutes()+today.getSeconds();

      // create link & download
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = src;
      a.download = `MM-QRCode-${date}.png`;
      a.click();
      document.body.removeChild(a);   
      
      //reset state -> clear field
      setInput("");
    }
  }

  // const handleLogout = () => {
  //   setLogout('logIn');
  // }

  return (
    <>
    <div className="qr-code">
      <aside className="sidebar">
        <div className="sidebar-inner">
          <div className="logo">
            <img src={mmLogoLarge} alt="AirBeamTV Logo"/>
          </div>
          <div className="control-container">
            <form
              onSubmit={createQR}
            >
              <h5>AirBeamTV</h5>
              <h2>QR generator</h2>

              <input
                type="text" 
                value={input}
                onChange={event => setInput(event.target.value)}
                name="input" 
                placeholder="e.g. www.airbeam.tv/"
              />
              
              <div className="btn-container">
                <button 
                  className="btn btn-secundary"
                  onClick={createQR}
                >
                  Create
                </button>

                <button 
                  className="btn btn-primary"
                  onClick={field === true ? downloadQR : (null)}
                >
                  Download
                </button>
              </div>

              <div className="href">
                <span>Generate link</span>
                <span><a href="https://bitly.com/" target="_blank" rel="noopener noreferrer">bitly.com</a></span>
              </div>
            </form> 
          </div>
          {/* <div className="logout">
            <button
              onClick={handleLogout}
            >
              Logout
            </button>
          </div> */}
        </div>
      </aside>
      <main>
          <div className="canvas-container">
            <div 
              ref={el => (qrCode = el)}
              id="qrcode"
              className={field === true ? ("created") : ""}
            />
          </div>
          <div className="copyright">
              <p>
                  <span>©</span>
                  <span>AirBeamTV, {date}</span>
              </p>
          </div>
        </main>
    </div>
    </>
  );
}

export default QrCode;
