import React, { useState } from 'react';
import QrCode from './components/QrCode/QrCode';
import mmLogo from './assets/mm-logo.png'
import './App.scss';

function App() {
  const [login, setLogin] = useState('logIn')
  const [values, setValues] = useState({
    user: "",
    password: ""
  });

  const handleChange = event => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [event.target.name] : event.target.value
    })
  }

  const handleSubmit = event => {
    event.preventDefault();
    submit();
  }

  function submit() {
    if (values.user === 'Admin' && values.password === 'QRCodeWebsite') {
      setLogin('logGedIn')
    }
  }


  return (
    <div 
    className={`app ${login === 'logIn' ? "login" : "loggedIn"}`}
    >
      
      {login === 'logGedIn' ? (<QrCode />) : (
        <div className="form">
          <div className="form-inner">
            <img src={mmLogo} alt="Logo AirBeamTV"/>
            <form onSubmit={handleSubmit} noValidate>
              <div className="input-container">
                <label>Username</label>
                <input
                  name="user" 
                  type="user" 
                  value={values.user}
                  onChange={handleChange}
                />
              </div>

              <div className="input-container">
                <label>Password</label>
                <input
                  name="password" 
                  type="password" 
                  value={values.password}
                  onChange={handleChange}
                />
              </div>

              <div className="btn-container">
                <button 
                  className="btn btn-primary"
                  type="submit"
                >
                  Login
                </button>
              </div>
            </form> 
            <div className="copyright">
                <p>
                    <span>qr.airbeam.tv</span>
                </p>
            </div>
          </div>
        </div>
      )}
      
    </div>
  );
}

export default App;
